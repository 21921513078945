import React, {useState, useEffect} from 'react';
import Logo from '../assets/login-logo-mono.png'
import { Image, Table, Grid, Header, GridRow, GridColumn, HeaderContent, Button, Input, HeaderSubheader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { getApp } from '../store/slice/app.slice';
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print';

export const WPrint = React.forwardRef((props, ref) => {
    const {renderTotal} = props
    const [clientDetails, setClientDetails] = useState({
        NAME: '',
        CONTACT_NUMBER: ''
    })

    const [loader, setLoader] = useState(false)
    const [code, setCode] = useState('')
    const [counter, setCounter] = useState()

    const {LOAD_DETAILS} = useSelector(getApp)

    const formWhole = {"width":"4in","height":"6in","fontFamily":"Lato"}
    const SA = {"width":"4in","height":"0.5in","borderBottom":"1px solid gray","justifyContent":"center", "margin":"auto", "display": "flex"}
    const logo = { "padding": "0.5rem 0.1rem"}
    const SB = {"width":"4in","height":"0.5in", "display":"flex"}
        const SB1 = {"font-size": "20px", "width":"4in","height":"0.5in", "textAlign":"center", "fontWeight":"bolder"}
        const SB2 = {"width":"1.1in","height":"0.5in", "paddingLeft":"3px", "fontWeight":"bolder", "textAlign": "center", "paddingRight":"5px", "marginTop":"3px"}
    const SC = {"width":"4in","height":"0.25in","borderBottom":"1px solid gray", "display":"flex"}
        const SC1 = {"width":"4in","height":"0.25in","borderRight":"1px solid gray", "paddingLeft":"8px"}
    const SD = {"width":"4in","height":"0.25in","borderBottom":"1px solid gray", "display":"flex"}
        const SD1 = {"width":"2in","height":"0.25in","borderRight":"1px solid gray", "paddingLeft":"8px"}
        const SD2 = {"width":"2in","height":"0.25in", "paddingLeft":"8px"}
    const SG = {"width":"4in","height":"4.2in","borderBottom":"1px solid gray", "display":"flex"}
    const SH = {"width":"4in","height":"0.3in", "textAlign":"center","paddingTop":"3px", "fontWeight":"bolder", "font-size": "11px"}
    const TB = {"padding": "0.5rem 1rem"}

    useEffect(() => {
        generateCode()
    },[])

    const generateCode = () => {
        const count = parseInt(window.localStorage.getItem('counter')) + 1

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yy = '24';

        let code = `W${yy}${mm}${dd}${count.toString()}`
        
        setCode(code)
        setCounter(count)
    }

    const handleUser = (e) => {
        const { value, name } = e.target
        setClientDetails({
            ...clientDetails,
            [name]: value.toUpperCase()
        })
    }

    const formatDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
	    var curHour = today.getHours() > 12 ? today.getHours() - 12 : (today.getHours() < 10 ? "0" + today.getHours() : today.getHours());
	    var curMinute = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
        var curMeridiem = today.getHours() > 12 ? "PM" : "AM";
        return (
            <>
            {mm + '-' + dd + '-' + yyyy}
            <br/>
            {curHour + ':' + curMinute + curMeridiem}
            </>
        );
    }

    const handlePrint = useReactToPrint({
        content: () => ref.current
    });

    const handleSave = () => {
        setLoader(true)
        const ls = JSON.parse(window.localStorage.getItem('angkols'))
        console.log(ls, typeof(ls))

        const obj = {
            ...ls,
            [code] : {
                NAME: clientDetails.NAME,
                CONTACT_NUMBER: clientDetails.CONTACT_NUMBER,
                LOAD_DETAILS: LOAD_DETAILS
            }, 
        }

        window.localStorage.setItem('angkols',JSON.stringify(obj))

        setTimeout(() => {
            setLoader(false)
            window.localStorage.setItem('counter',counter)
            window.location.reload()
        }, 2000);
    }

    const renderServicesAvailed = () => {
        console.log('LOAD_DETAILS',LOAD_DETAILS)
         
        const renderLoad = (c) => {
            return <div>{c.SERVICES_AVAILED.map((x,j)=>{
                return <div>{x.LABEL} - {x.PRICE}.00</div>
            })}</div>
        }
        const renderDetergent = (c) => {
            return <div>{c.DETERGENTS_AVAILED.map((x,j)=>{
                return <div>{x.LABEL} x {x.MULTIPLIER}- {x.NEW_PRICE}.00</div>
            })}</div>
        }
        const renderFabCon = (c) => {
            return <div>{c.FABCON_AVAILED.map((x,j)=>{
                return <div>{x.LABEL} x {x.MULTIPLIER}- {x.NEW_PRICE}.00</div>
            })}</div>
        }

        const style5 = {display: 'block', padding: '0.5rem', fontSize:"8px", lineHeight: '0.8rem', flex: '1'}
        const style4 = {display: 'block', padding: '0.5rem', fontSize:"9px", lineHeight: '0.9rem', flex: '1'}
        const style1 = {display: 'block', padding: '0.5rem', fontSize:"10px", lineHeight: '1rem', flex: '1'}
        

        return (
            <div style={LOAD_DETAILS.length === 5 ? style5 : (LOAD_DETAILS.length === 4 ? style4 : style1) }>
            {
                LOAD_DETAILS.map((c,i)=>{
                    return <div><b>LOAD# {i+1}</b>
                        {renderLoad(c)}
                        {renderDetergent(c)}
                        {renderFabCon(c)}
                    <br/></div>
                })
            }
            
            </div>
        )
    }
    
    const renderForm = () =>{
        return (
            <>
                <div className="form-whole" style={formWhole}>
                    <div style={SA}>
                    <Image src={Logo} style={logo} size='tiny'></Image>
                    </div>
                    <div style={SB}>
                        <div style={SB1}>
                            <Barcode value={code} 
                                format="CODE128" 
                                className="ss" 
                                displayValue={true} 
                                width="2"
                                height="15"
                                fontSize="10"
                            /> 
                            {/* <Header as={'h1'} style={{marginTop: '0.2rem'}}>W240830100</Header> */}
                        </div>
                        <div style={SB2}>
                        {formatDate()}
                        </div>
                    </div>
                    <div style={SC}>
                        <div style={SC1}>{clientDetails.NAME}</div>
                    </div>
                    <div style={SD}>
                        <div style={SD1}>{clientDetails.CONTACT_NUMBER}</div>
                        <div style={SD2}>{'WALK IN'}</div>
                    </div>
                    <div style={SG}>
                        {renderServicesAvailed()}
                        <div style={{flex: '1', marginTop: '11rem'}}>{renderTotal}</div>
                    </div>
                    <div style={SH}>
                        FB: Angkol's Laundelivery | PHONE: 0954 406 4923 | APP: angkols.app
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
        <Header as='h5' textAlign='left'>
            <HeaderSubheader>
            Name
            </HeaderSubheader>
            <Input fluid value={clientDetails.NAME} name='NAME' onChange={(e) => handleUser(e)}></Input>
        </Header>
        <Header as='h5' textAlign='left'>
            <HeaderSubheader>
            Contact Number
            </HeaderSubheader>
            <Input label='+63' fluid value={clientDetails.CONTACT_NUMBER} name='CONTACT_NUMBER' maxLength="10" onChange={(e) => handleUser(e)}></Input>
        </Header>
        <div style={formWhole} id="intshtz" ref={ref} >
            {renderForm()}
        </div>
        <br/>
        <Button primary onClick={handlePrint} disabled={clientDetails.NAME === '' || clientDetails.CONTACT_NUMBER === '' || clientDetails.CONTACT_NUMBER.length < 10}>PRINT WAY BILL</Button>
        <Button loading={loader} color={'green'} onClick={handleSave} disabled={clientDetails.NAME === '' || clientDetails.CONTACT_NUMBER === '' || clientDetails.CONTACT_NUMBER.length < 10}>SAVE</Button>
        </>
    );
  });