import React, { useState, useEffect } from 'react';
import { Label, Header, Grid, GridColumn, Segment, GridRow, Icon, List, ListItem, ListContent, Button, ListHeader, ListIcon, Divider, ListDescription } from 'semantic-ui-react';

const Transactions = () => {
    const [step, useStep] = useState(1)
    const [gotUri, setUri] = useState('')

    useEffect(()=>{
        var uri = window.location.search
        uri = uri.replace('?c=','');
        setUri(uri)
    },[])

    const handleIcon = (i) => {
        return `${step >= i && 'check'} circle`
    }

    // 240814001

    return (
        <div className="transactions">
            <div className='box-bg'></div>
            <br/>
            <Segment raised color='blue' clearing className='active-order'>
            <Header as="h2" className='m0'>Waiting for store confirmation...</Header>
            {`Transaction Code: ${gotUri}`}
            <Grid className='spf'>
                <GridRow columns={4} className='stepper-ui-icons'>
                    <GridColumn className='icon-grid' width={4}>
                        <Icon name={handleIcon(1)} color="blue" size='huge'></Icon>
                    </GridColumn>

                    <GridColumn className='icon-grid' width={4}>
                        <Icon name={handleIcon(2)} color="blue" size='huge'></Icon>
                    </GridColumn>

                    <GridColumn className='icon-grid' width={4}>
                        <Icon name={handleIcon(2)} color="blue" size='huge'></Icon>
                    </GridColumn>

                    <GridColumn className='icon-grid' width={4}>
                        <Icon name={handleIcon(2)} color="blue" size='huge'></Icon>
                    </GridColumn>

                </GridRow>
            </Grid>
            <Grid className='spf'>
                <GridRow columns={4} className='stepper-ui-icons'>
                    <GridColumn width={4}>
                        <div className='txt-step'><span>Order Confirmed</span></div>
                    </GridColumn>
                    <GridColumn width={4}>
                        <div className='txt-step'><span>Picked up</span></div>
                    </GridColumn>
                    <GridColumn width={4}>
                        <div className='txt-step'><span>Washed and Dried</span></div>
                    </GridColumn>
                    <GridColumn width={4}>
                        <div className='txt-step'><span>Delivered</span></div>
                    </GridColumn>
                </GridRow>
            </Grid>
            <br/>
            {/* <Button compact fluid color="yellow">View Transaction Details</Button> */}
            </Segment>
        </div>
    )
}

export default Transactions