import ANGKOLS from '../assets/angkols.png'
import ARIEL_LD from '../assets/ariel ld.png'
import BREEZE_LD from '../assets/breeze ld.png'
import CALLA_FC from '../assets/calla fc.png'
import DOWNY_FC from '../assets/downy fc.png'
import TIDE_LD from '../assets/tide ld.png'

export const brgys = [
    {key: "BARANGAY 1 (POB.)",text: "BARANGAY 1 (POB.)",value:"BARANGAY 1 (POB.)"},
    {key: "BARANGAY 2 (POB.)",text: "BARANGAY 2 (POB.)",value:"BARANGAY 2 (POB.)"},
    {key: "BARANGAY 3 (POB.)",text: "BARANGAY 3 (POB.)",value:"BARANGAY 3 (POB.)"},
    {key: "BARANGAY 4 (POB.)",text: "BARANGAY 4 (POB.)",value:"BARANGAY 4 (POB.)"},
    {key: "BARANGAY 5 (POB.)",text: "BARANGAY 5 (POB.)",value:"BARANGAY 5 (POB.)"},
    {key: "BARANGAY 6 (POB.)",text: "BARANGAY 6 (POB.)",value:"BARANGAY 6 (POB.)"},
    {key: "BARANGAY 7 (POB.)",text: "BARANGAY 7 (POB.)",value:"BARANGAY 7 (POB.)"},
    {key: "BAGONG KALSADA",text: "BAGONG KALSADA",value:"BAGONG KALSADA"},
    {key: "BANADERO",text: "BANADERO",value:"BANADERO"},
    {key: "BANLIC",text: "BANLIC",value:"BANLIC"},
    {key: "BARANDAL",text: "BARANDAL",value:"BARANDAL"},
    {key: "BATINO",text: "BATINO",value:"BATINO"},
    {key: "BUBUYAN",text: "BUBUYAN",value:"BUBUYAN"},
    {key: "BUCAL",text: "BUCAL",value:"BUCAL"},
    {key: "BUNGGO",text: "BUNGGO",value:"BUNGGO"},
    {key: "BUROL",text: "BUROL",value:"BUROL"},
    {key: "CAMALIGAN",text: "CAMALIGAN",value:"CAMALIGAN"},
    {key: "CANLUBANG",text: "CANLUBANG",value:"CANLUBANG"},
    {key: "HALANG",text: "HALANG",value:"HALANG"},
    {key: "HORNALAN",text: "HORNALAN",value:"HORNALAN"},
    {key: "KAY-ANLOG",text: "KAY-ANLOG",value:"KAY-ANLOG"},
    {key: "LAGUERTA",text: "LAGUERTA",value:"LAGUERTA"},
    {key: "LA MESA",text: "LA MESA",value:"LA MESA"},
    {key: "LAWA",text: "LAWA",value:"LAWA"},
    {key: "LECHERIA",text: "LECHERIA",value:"LECHERIA"},
    {key: "LINGGA",text: "LINGGA",value:"LINGGA"},
    {key: "LOOC",text: "LOOC",value:"LOOC"},
    {key: "MABATO",text: "MABATO",value:"MABATO"},
    {key: "MAKILING",text: "MAKILING",value:"MAKILING"},
    {key: "MAPAGONG",text: "MAPAGONG",value:"MAPAGONG"},
    {key: "MASILI",text: "MASILI",value:"MASILI"},
    {key: "MAUNONG",text: "MAUNONG",value:"MAUNONG"},
    {key: "MAYAPA",text: "MAYAPA",value:"MAYAPA"},
    {key: "MILAGROSA",text: "MILAGROSA",value:"MILAGROSA"},
    {key: "PACIANO RIZAL",text: "PACIANO RIZAL",value:"PACIANO RIZAL"},
    {key: "PALINGON",text: "PALINGON",value:"PALINGON"},
    {key: "PALO-ALTO",text: "PALO-ALTO",value:"PALO-ALTO"},
    {key: "PANSOL",text: "PANSOL",value:"PANSOL"},
    {key: "PARIAN",text: "PARIAN",value:"PARIAN"},
    {key: "PRINZA",text: "PRINZA",value:"PRINZA"},
    {key: "PUNTA",text: "PUNTA",value:"PUNTA"},
    {key: "PUTING LUPA",text: "PUTING LUPA",value:"PUTING LUPA"},
    {key: "REAL",text: "REAL",value:"REAL"},
    {key: "SUCOL",text: "SUCOL",value:"SUCOL"},
    {key: "SAIMSIM",text: "SAIMSIM",value:"SAIMSIM"},
    {key: "SAMPIRUHAN",text: "SAMPIRUHAN",value:"SAMPIRUHAN"},
    {key: "SAN CRISTOBAL",text: "SAN CRISTOBAL",value:"SAN CRISTOBAL"},
    {key: "SAN JOSE",text: "SAN JOSE",value:"SAN JOSE"},
    {key: "SAN JUAN",text: "SAN JUAN",value:"SAN JUAN"},
    {key: "SIRANG LUPA",text: "SIRANG LUPA",value:"SIRANG LUPA"},
    {key: "TURBINA",text: "TURBINA",value:"TURBINA"},
    {key: "ULANGO",text: "ULANGO",value:"ULANGO"},
    {key: "UWISAN",text: "UWISAN",value:"UWISAN"},
    {key: "MAJADA LABAS",text: "MAJADA LABAS",value:"MAJADA LABAS"}
]

export const PAGES = {
    REQUEST: "/",
    PROFILE: "/profile",
    TRANSACTIONS: "/transactions",
    WALKINS: "/walkin"
}

export const LAUNDRY_DETERGENT = [
    {key: "ANGKOLS 60g",text: "ANGKOLS 60g",value:"ANGKOLS 60g", image: { src: ANGKOLS }},
    {key: "BREEZE 60g",text: "BREEZE 60g",value:"BREEZE 60g", image: { src: BREEZE_LD }},
    {key: "TIDE 60g",text: "TIDE 60g",value:"TIDE 60g", image: { src: TIDE_LD }},
    {key: "ARIEL 60g",text: "ARIEL 60g",value:"ARIEL 60g", image: { src: ARIEL_LD }},
]

export const FABRIC_CONDITIONER = [
    {key: "ANGKOLS 60g",text: "ANGKOLS 60g",value:"ANGKOLS 60g", image: { src: ANGKOLS }},
    {key: "CALLA 60g",text: "CALLA 60g",value:"CALLA 60g", image: { src: CALLA_FC }},
    {key: "DOWNY 60g",text: "DOWNY 60g",value:"DOWNY 60g", image: { src: DOWNY_FC }},
]

export const WASH_TYPE = {
    DELICATE: "DELICATE",
    NORMAL: "NORMAL",
    HEAVY: "HEAVY"
}

export const SERVICE_PRICES = {
    DELICATE_WASH: {
        PRICE: 69,
        LABEL: 'DELICATE WASH (28mins)'
    },
    NORMAL_WASH: {
        PRICE: 79,
        LABEL: 'NORMAL WASH (38mins)'
    },
    HEAVY_WASH: {
        PRICE: 89,
        LABEL: 'HEAVY WASH (48mins)'
    },
    DRY: {
        PRICE: 89,
        LABEL: 'DRY (38mins)'
    },
    FOLD: {
        PRICE: 40,
        LABEL: 'FOLD'
    },
    ALD: {
        PRICE: 0,
        LABEL: 'Angkol`s Liquid Detergent',
        MULTIPLIER: 1,
        NEW_PRICE: 0
    },
    AFC: {
        PRICE: 0,
        LABEL: 'Angkol`s Fabric Conditioner',
        MULTIPLIER: 1,
        NEW_PRICE: 0
    },
    TIDE_LD: {
        PRICE: 15,
        LABEL: 'Tide 60g',
        MULTIPLIER: 1,
        NEW_PRICE: 15
    },
    BREEZE_LD: {
        PRICE: 15,
        LABEL: 'Breeze 60g',
        MULTIPLIER: 1,
        NEW_PRICE: 15
    },
    ARIEL_LD: {
        PRICE: 15,
        LABEL: 'Ariel 60g',
        MULTIPLIER: 1,
        NEW_PRICE: 15
    },    
    DOWNY_FC: {
        PRICE: 15,
        LABEL: 'Downy 60g',
        MULTIPLIER: 1,
        NEW_PRICE: 15
    },
    CALLA_FC: {
        PRICE: 15,
        LABEL: 'Calla 60g',
        MULTIPLIER: 1,
        NEW_PRICE: 15
    }
}

export const MAP_API = "AIzaSyCzl1J8n6APBiZTrCHhGwPYE0TAqCQZkI0"
export const TRANSACTIONS = 'transactions'
export const COUNT = 'count'
export const META_COUNT = 'META_COUNT'