
import { createSlice } from '@reduxjs/toolkit';

const shipDeets = {
    PICK_UP_TOGGLE: true,
    DELIVERY_TOGGLE: true,
    FEES: {
        PICK_UP_FEE: 0,
        DELIVERY_FEE: 0,
        TOTAL_AMOUNT: 0,
    },
    DESTINATION: {
        KMS: '',
        LAT: '',
        LNG: '',
        ADDRESS_COMPLETE: '',
        ADDRESS_LANDMARK: '',
    },
    USER: {
        NAME: '',
        CONTACT_NUMBER: ''
    },
    ORIGIN: 'Angkols Laundelivery Brgy 3 Calamba Laguna',
    VALIDATION_NOTICE: '',
    PICK_UP_DATE: 'SAME-DAY',
    DELIVERY_DATE: 'SAME-DAY',
}

const initialState = {
    USER_DETAILS: shipDeets,
    LOAD_DETAILS: [],
    LOADER: false,
    NOTIF_MODAL: {
        OPEN: false,
        HEADER: '',
        CONTENT: '',
        HANDLER_TEXT: ''
    }
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLoad: (state, action) => ({ ...state, LOADER: action.payload  }),
        setModal: (state, action) => ({ ...state, NOTIF_MODAL: action.payload  }),
        setDestination: (state, action) => ({ ...state, 
            USER_DETAILS: {
                ...state.USER_DETAILS,
                DESTINATION: {
                    ...state.USER_DETAILS.DESTINATION,
                    ...action.payload,
                }
            }
        }),
        setFees: (state, action) => ({ ...state, 
            USER_DETAILS: {
                ...state.USER_DETAILS,
                FEES: {
                    ...state.USER_DETAILS.FEES,
                    ...action.payload,
                }
            }
        }),
        setUser: (state, action) => ({ ...state, 
            USER_DETAILS: {
                ...state.USER_DETAILS,
                USER: {
                    ...state.USER_DETAILS.USER,
                    ...action.payload,
                }
            }
        }),
        setToggles: (state, action) => ({ ...state, 
            USER_DETAILS: {
                ...state.USER_DETAILS,
                ...action.payload
            }
        }),
        setLoadDetails: (state, action) => ({ ...state, 
            LOAD_DETAILS: action.payload
        }),
    }
})

export const getApp = (state) => state.app;
export default appSlice;
