import React, { useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import appSlice, { getApp } from '../store/slice/app.slice';
import { useSelector } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalContent,
    ModalActions, 
    Button,
    List,
    ListItem,
    ListContent,
    Header
} from 'semantic-ui-react';


const WalkIns = () => {
    const [listModal, setListModal] = useState(true)
    const { LOAD_DETAILS } = useSelector(getApp)

    useEffect(()=>{
        const counter = window.localStorage.getItem('counter')

        if(counter === null){
            window.localStorage.setItem('counter',100)
        }
    },[])

    const renderList = () => {
        const ls = JSON.parse(window.localStorage.getItem('angkols'))

        if(ls){
            let arrayOfKeys = Object.keys(ls);

            const list = arrayOfKeys?.map((i,k) => {
                return (
                        <ListItem>
                            <ListContent floated='right'> <Button compact >View</Button> </ListContent>
                            <ListContent>
                                <Header as="h5">{i}</Header>
                                {`${ls[i].NAME} - ${ls[i].CONTACT_NUMBER}`}
                            </ListContent>
                        </ListItem>
                )
            });

            return <List divided verticalAlign='middle'>{list}</List>
        }

        return 'No transactions'
    }

    const renderModal = () => {


        return <Modal
            className='general-modal'
            size={'tiny'}
            open={listModal}
            dimmer={'blurring'}
            closeOnDimmerClick={false}
        >
            <ModalHeader>{'Walkin Transactions'}</ModalHeader>
            <ModalContent>
            {renderList()}
            </ModalContent>
            <ModalActions>
            <Button color="blue" onClick={() => setListModal(false)}>
                {'Create work order'}
            </Button>
            </ModalActions>
        </Modal>
    }

    return (
        <>
        <Dashboard isWalkIn={true}/>
        {renderModal()}
        </>
    )
}

export default WalkIns