import React, { useEffect, useState, useCallback } from 'react';
import { FormField, Header, HeaderSubheader, Input } from 'semantic-ui-react';
import { GoogleMap, useLoadScript, Marker, DistanceMatrixService } from '@react-google-maps/api';
import { MAP_API } from '../utils/constants';
import LocPin from '../assets/icon-map-logo.png'
import { useDispatch, useSelector } from 'react-redux';
import appSlice, { getApp } from '../store/slice/app.slice';
import axios from 'axios';

function MapComponent() {
  const [denied, setDenied] = useState(false)
  const [zoom, setZoom] = useState(20)
  const [address, setAddress] = useState('')
  const angkolStore = {lat: 14.207122235982897, lng: 121.16178457348528}
  const dispatch = useDispatch()
  const { USER_DETAILS } = useSelector(getApp)
  const { DESTINATION, USER } = USER_DETAILS
  const { LAT, LNG } = DESTINATION
  const { setLoad, setDestination, setFees, setUser, setModal } = appSlice.actions

  useEffect(() => {
    dispatch(setLoad(true))
    const getLocSubscribe = async() => {
      const res = await getLoc()
      return res
    }
    getLocSubscribe()
  },[]);

  const getLoc = async() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          dispatch(setDestination({LAT: latitude, LNG: longitude}))
          getDistance(latitude, longitude)

          dispatch(setLoad(false))
          setZoom(13)
        },
        (error) => {
          if(error.code === 1){
            setDenied(true)
          }
        }
      );
    }
    else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  const getDistance = async(latitude, longitude) => {
    const apiKey = MAP_API;
    const origin = `${angkolStore.lat},${angkolStore.lng}`
    const destination = `${latitude},${longitude}`

    var service = new window.google.maps.DistanceMatrixService();

    if(service){
      await service.getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        travelMode: 'DRIVING',
      },(res, status) => {
        console.log('vvv', res, status)
          if(status === 'OK'){
            console.log('ok-data-red')
            const distanceInMeters = res.rows[0].elements[0].distance.value;
            const km = distanceInMeters/1000

            dispatch(setDestination({KMS: km, ADDRESS_COMPLETE: res.destinationAddresses}))
            dispatch(setFees(calculateFees(km)))
          }else{
            dispatch(setModal({
              OPEN: true,
              HEADER: 'Network Error',
              CONTENT: 'Please try again',
              HANDLER_TEXT: 'Reload'
            }))
          }
        }
      )
    }
  }

  // MAP RELATED
  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: `${MAP_API}&loading=async`
  });

  const mapContainerStyle = {
    width: '100%',
    height: '20vh',
  };

  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(angkolStore)
    map.fitBounds(bounds);
    map.setZoom(12);
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleAddress = (e) => {
    const { value, name } = e.target
    dispatch(setDestination({[name]: value}))
  }

  const handleUser = (e) => {
    const { value, name } = e.target
    dispatch(setUser({[name]: value}))
  }

  // DISTANCE LOGIC

  const calculateFees = (km) => {
    let pickupfee = 0
    let delfee = 0
    
    if(km < 1.0){
      pickupfee = 0
      delfee = 40
    }else if(km < 2.0){
      pickupfee = 50
      delfee = 50
    }else if(km < 3.0){
      pickupfee = 60
      delfee = 60
    }else if(km < 4.0){
      pickupfee = 70
      delfee = 70
    }else if(km < 5.0){
      pickupfee = 80
      delfee = 80
    }else{
      pickupfee = 0
      delfee = 0
    }

    return {
      PICK_UP_FEE: pickupfee, 
      DELIVERY_FEE: delfee, 
      TOTAL_AMOUNT: pickupfee+delfee
    }
  }

  // RENDERS

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const renderFields = () => {
    return (
      <>
        <Header as='h5' textAlign='left'>
            <HeaderSubheader>
            Name
            </HeaderSubheader>
            <Input fluid value={USER.NAME} name='NAME' onChange={(e) => handleUser(e)}></Input>
        </Header>
        <Header as='h5' textAlign='left'>
            <HeaderSubheader>
            Contact Number
            </HeaderSubheader>
            <Input label='+63' fluid value={USER.CONTACT_NUMBER} name='CONTACT_NUMBER' maxLength="10" onChange={(e) => handleUser(e)}></Input>
        </Header>
        <Header as='h5' textAlign='left'>
          <FormField>
              <label className='cbk'>Complete Address</label>
              <Input fluid value={DESTINATION.ADDRESS_COMPLETE} name='ADDRESS_COMPLETE' onChange={(e) => handleAddress(e)}></Input>
          </FormField>
        </Header>
        <Header as='h5' textAlign='left'>
            <HeaderSubheader>
            Landmark
            </HeaderSubheader>
            <Input fluid value={DESTINATION.ADDRESS_LANDMARK} name='ADDRESS_LANDMARK' onChange={(e) => handleAddress(e)}></Input>
        </Header>
      </>
    )
  }

  const renderMap = () => {
    return isLoaded ? <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={zoom}
        disableDefaultUI={true}
        fullscreenControl={false}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={{lat: LAT, lng: LNG}} />
        <Marker position={angkolStore} icon={LocPin} />
      </GoogleMap> : 'null'
  }
 
  return (
    <div className='map-component'>
      {renderMap()}
      {renderFields()}
    </div>
  );
}

export default MapComponent;