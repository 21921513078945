import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCU4P_eMf5aTA2iVOgnrx1IsMrFdbLFUc0",
  authDomain: "angkols-4887e.firebaseapp.com",
  projectId: "angkols-4887e",
  storageBucket: "angkols-4887e.appspot.com",
  messagingSenderId: "147250305035",
  appId: "1:147250305035:web:d3a607740dde52da2ecf0e",
  measurementId: "G-Q0GNWG81S5"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app)

export default app;
