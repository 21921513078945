import React, { useEffect, useRef, useState } from 'react';
import {
    Icon,
    Header,
    HeaderContent,
    HeaderSubheader,
    Button,
    FormField,
    Grid,
    GridRow,
    Dropdown,
    ButtonGroup,
    Label,
    Checkbox,
    Accordion,
    AccordionTitle,
    AccordionContent,
    GridColumn,
    Segment,
    Divider,
    Image,
    Dimmer,
    Loader,
    Modal,
    ModalHeader,
    ModalContent,
    ModalActions
} from 'semantic-ui-react';

import { COUNT, META_COUNT, FABRIC_CONDITIONER, LAUNDRY_DETERGENT, PAGES, SERVICE_PRICES, TRANSACTIONS, WASH_TYPE } from '../utils/constants';
import { SwipeableButton } from "react-swipeable-button";
import MapComponent from './MapComponent';
import { WPrint } from './WPrint';
import LoadingMachine from "../assets/loading-laundry.gif"
import appSlice, { getApp } from '../store/slice/app.slice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {  doc, runTransaction, Timestamp, serverTimestamp } from "firebase/firestore"; 
import { db } from '../firebase';
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';

const Dashboard = ({
    isWalkIn
}) => {
    const [step, setStep] = useState(1)
    const [activeAccordionIndex, setAccordionIndex] = useState(0)
    const [isConfirmed, setConfirmed] = useState(false)
    const [tcode, setTCode] = useState()
    const [printModal, setPrintModal] = useState(false)
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { setLoadDetails, setModal, setToggles, setLoad } = appSlice.actions
    const { LOAD_DETAILS, LOADER, USER_DETAILS, NOTIF_MODAL } = useSelector(getApp)

    const componentRefWB = useRef();
   
    const defaultDeets = {
        TYPE_OF_WASH: '',
        LAUNDRY_DETERGENT: 'ANGKOLS 60g',
        LAUNDRY_DETERGENT_QTY: 1,
        FABRIC_CONDITIONER: 'ANGKOLS 60g',
        FABRIC_CONDITIONER_QTY: 1,
        HAS_LAUNDRY_DETERGENT: true,
        HAS_FABRIC_CONDITIONER: true,
        SERVICES_AVAILED: [],
        DETERGENTS_AVAILED: [SERVICE_PRICES.ALD],
        FABCON_AVAILED: [SERVICE_PRICES.AFC]
    }

    const [data, setData] = useState({
        LOAD_DETAILS: [],
    })

    // USEEFFECT 
    useEffect(()=>{
        const {PICK_UP_TOGGLE, DELIVERY_TOGGLE} = USER_DETAILS
        if((PICK_UP_TOGGLE === false && DELIVERY_TOGGLE === false) || (PICK_UP_TOGGLE === false && DELIVERY_TOGGLE === true)){
            setTimeout(()=>{
                dispatch(setToggles({PICK_UP_TOGGLE: true, DELIVERY_TOGGLE: true}))
            },[500])
        }
    },[USER_DETAILS.PICK_UP_TOGGLE, USER_DETAILS.DELIVERY_TOGGLE])

    useEffect(()=>{
        dispatch(setToggles({
            VALIDATION_NOTICE: USER_DETAILS.DELIVERY_TOGGLE === false ? 
            '** Note that failure to pick-up the laundry within the day will have penalties **' : 
            ''
        }))
    },[USER_DETAILS.DELIVERY_TOGGLE])
    
    const getServicePricing = (wash, k) => {
        if(WASH_TYPE.DELICATE === wash){
            return [ SERVICE_PRICES.DELICATE_WASH, SERVICE_PRICES.DRY, SERVICE_PRICES.FOLD ]
        }else if(WASH_TYPE.NORMAL === wash){
            return [ SERVICE_PRICES.NORMAL_WASH, SERVICE_PRICES.DRY, SERVICE_PRICES.FOLD ]
        }else{
            return [ SERVICE_PRICES.HEAVY_WASH, SERVICE_PRICES.DRY, SERVICE_PRICES.FOLD ]
        }
    }

    const getDetergentPricing = (detergent,k) => {
        switch(detergent){
            case 'ANGKOLS 60g' : return[SERVICE_PRICES.ALD]
            case 'BREEZE 60g' : return[SERVICE_PRICES.BREEZE_LD]
            case 'TIDE 60g' : return[SERVICE_PRICES.TIDE_LD]
            case 'ARIEL 60g' : return[SERVICE_PRICES.ARIEL_LD]
        }
    }

    const getFabConPricing = (fabcon,k) => {
        switch(fabcon){
            case 'ANGKOLS 60g' : return[SERVICE_PRICES.AFC]
            case 'CALLA 60g' : return[SERVICE_PRICES.CALLA_FC]
            case 'DOWNY 60g' : return[SERVICE_PRICES.DOWNY_FC]
        }
    }

    // HANDLERS
    const handleChangeLoad = (type) => {
        let loadDeets = LOAD_DETAILS
        
        if(type === '+'){
            loadDeets = [
                ...loadDeets,
                defaultDeets
            ]
        }else{
            loadDeets = [...loadDeets.slice(0, loadDeets.length-1), ...loadDeets.slice((loadDeets.length-1) + 1)];
        }

        dispatch(setLoadDetails(loadDeets))
    }

    const handleAccordionClick = (k) => {
        setAccordionIndex(activeAccordionIndex === k ? -1 : k)
    }

    const handleSoapSelectionLD = (e, {name, value}, k) => {
        const newArr = LOAD_DETAILS.map((c,i)=>{
            if(i === k){
                return {
                    ...c,
                    LAUNDRY_DETERGENT: value,
                    DETERGENTS_AVAILED: getDetergentPricing(value, k)
                }
            }else{
                return c
            }
        })

        dispatch(setLoadDetails(newArr))
    }

    const handleSoapSelectionFC = (e, {name, value}, k) => {
        const newArr = LOAD_DETAILS.map((c,i)=>{
            if(i === k){
                return {
                    ...c,
                    FABRIC_CONDITIONER: value,
                    FABCON_AVAILED: getFabConPricing(value, k)
                }
            }else{
                return c
            }
        })

        dispatch(setLoadDetails(newArr))
    }

    const handleCheckboxToggle = (d, k) => {

        const newArr = LOAD_DETAILS.map((c,i)=>{
            if(i === k){
                return {
                    ...c,
                    HAS_LAUNDRY_DETERGENT: d.name === 'ld' ? d.checked : c.HAS_LAUNDRY_DETERGENT,
                    HAS_FABRIC_CONDITIONER: d.name === 'fc' ? d.checked : c.HAS_FABRIC_CONDITIONER,

                    DETERGENTS_AVAILED: d.name === 'ld' ? ( !d.checked ? [] : [SERVICE_PRICES.ALD] ) : c.DETERGENTS_AVAILED,
                    FABCON_AVAILED: d.name === 'fc' ? ( !d.checked ? [] : [SERVICE_PRICES.AFC] ) : c.FABCON_AVAILED,

                    LAUNDRY_DETERGENT: d.name === 'ld' ? 'ANGKOLS 60g' : c.LAUNDRY_DETERGENT,
                    LAUNDRY_DETERGENT_QTY: d.name === 'ld' ? 1 : c.LAUNDRY_DETERGENT_QTY,

                    FABRIC_CONDITIONER: d.name === 'fc' ? 'ANGKOLS 60g' : c.FABRIC_CONDITIONER,
                    FABRIC_CONDITIONER_QTY: d.name === 'fc' ? 1 : c.FABRIC_CONDITIONER_QTY
                }
            }else{
                return c
            }
        })

        dispatch(setLoadDetails(newArr))
    }

    const handleShippingToggle = (d) => {
        const {name, checked} = d
        
        dispatch(setToggles({ [name]: checked }))
    }

    const handleTypeOfWashButton = (washType, k) => {
        const newArr = LOAD_DETAILS.map((c,i)=>{
            if(i === k){
                return {
                    ...c,
                    TYPE_OF_WASH: washType,
                    SERVICES_AVAILED: getServicePricing( washType, k )
                }
            }
            return c
        })

        dispatch(setLoadDetails(newArr))
    }

    const handleColor = (i, wt) => {
        return i ? (i.TYPE_OF_WASH === wt ? 'blue' : '') : ''
    }

    const handleSoapLoadLD = (indicator, k) => {
        let soapLoad = LOAD_DETAILS[k].LAUNDRY_DETERGENT_QTY
        if(indicator === '+'){
            soapLoad = soapLoad+1
        }else{
            soapLoad = soapLoad-1
        }

        let validate = soapLoad > 0 && soapLoad < 4

        if(validate){
            const newArr = LOAD_DETAILS.map((c,i)=>{
                let validate = soapLoad > 0 && soapLoad < 4

                if(i === k){
                    return {
                        ...c,
                        LAUNDRY_DETERGENT_QTY: validate ? soapLoad : LOAD_DETAILS[k].LAUNDRY_DETERGENT_QTY,
                        DETERGENTS_AVAILED: [{
                            ...c.DETERGENTS_AVAILED[0],
                            MULTIPLIER: validate ? soapLoad : LOAD_DETAILS[k].LAUNDRY_DETERGENT_QTY,
                            NEW_PRICE: soapLoad * c.DETERGENTS_AVAILED[0]?.PRICE
                        }]
                    }
                }

                return c
            })

            dispatch(setLoadDetails(newArr))
        }
    }

    const handleSoapLoadFC = (indicator, k) => {
        let soapLoad = LOAD_DETAILS[k].FABRIC_CONDITIONER_QTY

        if(indicator === '+'){
            soapLoad = soapLoad+1
        }else{
            soapLoad = soapLoad-1
        }

        let validate = soapLoad > 0 && soapLoad < 4

        if(validate){
            const newArr = LOAD_DETAILS.map((c,i)=>{
                if(i === k){
                    return {
                        ...c,
                        FABRIC_CONDITIONER_QTY: soapLoad > 0 && soapLoad < 4 ? soapLoad : LOAD_DETAILS[k].FABRIC_CONDITIONER_QTY,
                        FABCON_AVAILED: [{
                            ...c.FABCON_AVAILED[0],
                            MULTIPLIER: validate ? soapLoad : LOAD_DETAILS[k].FABRIC_CONDITIONER_QTY,
                            NEW_PRICE: soapLoad * c.FABCON_AVAILED[0]?.PRICE
                        }]
                    }
                }

                return c
            })

            dispatch(setLoadDetails(newArr))
        }
    }

    const handleNextBtn = () => {
        setStep(step+1)
    }

    const handleBackPickDel = () => {
        setStep(step-1)
    }

    const handleModal = () => {
        setModal({
            ...NOTIF_MODAL,
            OPEN: !NOTIF_MODAL.OPEN
        })
    }

    const generateTID = () => {
        var options = {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit"
        };

        let stamp = Timestamp.now().toDate().toLocaleString("en", options);;
        let sep = stamp.split("/");

        return Array.isArray ? `${sep[2]}${sep[0]}${sep[1]}` : `000000`
    }

    const handleSuccess = async() => {
        dispatch(setLoad(true))

        // ADD TO LOCAL STORAGE

        try {
            await runTransaction(db, async (transaction) => {
                // INITIALIZE COUNT
                const countRef = doc(db, COUNT, META_COUNT);
                const sfDoc = await transaction.get(countRef);

                // INCREMENT COUNT
                const newCount = sfDoc.data().C + 1;
                
                // DECLARE TIMESTAMP
                let code = `A${generateTID()}${newCount.toString()}`
                const newRef = doc(db, TRANSACTIONS, code);
                
                const res = await transaction.set(newRef, {
                    USER_DETAILS: USER_DETAILS,
                    LOAD_DETAILS: LOAD_DETAILS,
                    TIMESTAMP: serverTimestamp(),
                    META_CODE: code,
                    STATUS: 'ORDER_SUBMITTED'
                });

                transaction.update(countRef, { C: newCount });

                axios.post('https://api.semaphore.co/api/v4/messages', {
                    "apikey": "f3127c99224d3faca1b432e3ae92bada",
                    "recipient": "639568177614",
                    "message": "Thank you for your request for pick-up with Angkol's Laundelivery. Check your transaction here: https://angkols.app/transactions?c=A240901105",
                    "sender_name": "Angkols",
                })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
                
                setTCode(code)
                setConfirmed(true)
                dispatch(setLoad(false)) 
            });
            
          } catch (e) {
            console.log("Transaction failed: ", e);
          }

        
    }

    const handleIcon = (i) => {
        return `circle outline${step >= i ? ' check' : ''}`
    }

    const handleGoToTransactionsPage = () => {
        navigate(`${PAGES.TRANSACTIONS}?c=${tcode}`)
    }

    // RENDERS

    const validate = () => {
        let arr = LOAD_DETAILS.map((i, k)=>{
            return LOAD_DETAILS[k]?.TYPE_OF_WASH
        })

        return (arr.length!==0 && !arr.includes(''))
    }

    const validate2 = () => {
        const {USER, FEES, DESTINATION} = USER_DETAILS
        const {NAME, CONTACT_NUMBER} = USER_DETAILS
        const {TOTAL_AMOUNT} = FEES
        const {LAT, LNG, KMS, ADDRESS_COMPLETE, ADDRESS_LANDMARK} = DESTINATION
  
        return NAME !== '' && CONTACT_NUMBER !== '' && TOTAL_AMOUNT!==0 && LAT!== '' && LNG!== '' && KMS < 5 && ADDRESS_COMPLETE !== '' && ADDRESS_LANDMARK !== ''
    }

    const renderConfirmButton = () => {
        return (
            <div className="w-[500px] h-[100px]">             
                <SwipeableButton
                    onSuccess={handleSuccess}
                    text="SWIPE TO CONFIRM" 
                    text_unlocked="REQUEST CONFIRMED" 
                    color="#2185D0" 
                />
            </div>
        )
    }

    const renderFooter = () => {
        return (
            <div className='d-footer'>
                {
                    step != 3 ? (
                        <Button fluid size='big' compact={true} color={'blue'}
                        onClick={handleNextBtn}
                        disabled={step === 1 ? !validate() : !validate2() }
                        >NEXT</Button> 
                    ): !isConfirmed ? renderConfirmButton() : ''
                }
            </div>
        )
    }

    const renderPickDeliveryOptions = () => {
        return (
            <>
            <Header as='h5' onClick={handleBackPickDel} color={'blue'} className='m1'>
                <Icon name='arrow circle left' />
                <HeaderContent>Back</HeaderContent>
            </Header>
            
            <Segment className='pick-delivery-opts m1' raised>
                <Header as='h3' color={'blue'} style={{margin: '0px !important', textAlign:'left'}}>
                    Pick-up and Delivery Details
                </Header>

                <MapComponent />
            </Segment> 
            {renderShippingComputation()}
            </>
        )
    }

    const renderShippingComputation = () => {
        const { PICK_UP_TOGGLE, DELIVERY_TOGGLE, FEES, VALIDATION_NOTICE } = USER_DETAILS
        const { PICK_UP_FEE, DELIVERY_FEE } = FEES

        return (
            <>
            <br/>
            <Divider horizontal>Pick up and Delivery Computation</Divider>
            
            <Label color="yellow">Free pick up if less than 1km from our store!</Label>
            <br/>   
            <br/>   

            <Grid>
                <GridRow columns={1} className='p51'>
                </GridRow>  
                <GridRow columns={2} className='p51'>
                    <GridColumn><Checkbox name='PICK_UP_TOGGLE' onChange={(e, d) => handleShippingToggle(d)} checked={PICK_UP_TOGGLE} toggle className='mt1' label="Pick-up Fee"/></GridColumn>
                    <GridColumn><b>{`Php ${PICK_UP_FEE}.00`}</b></GridColumn>
                </GridRow>
                <GridRow columns={2} className='p51'>
                    <GridColumn><Checkbox name='DELIVERY_TOGGLE' onChange={(e, d) => handleShippingToggle(d)} checked={DELIVERY_TOGGLE} toggle className='mt1' label="Delivery Fee"/></GridColumn>
                    <GridColumn><b>{`Php ${DELIVERY_TOGGLE === false ? 0 : DELIVERY_FEE}.00`}</b></GridColumn>
                </GridRow>
                <p className='validation-notice'>{VALIDATION_NOTICE}</p>
                {renderTotal('delivery')}
                <br/><br/><br/><br/><br/>
            </Grid>
            </>
        )
    }

    const renderConfirm = () => {
        const {FEES, DELIVERY_TOGGLE} = USER_DETAILS
        const {PICK_UP_FEE, DELIVERY_FEE} = FEES
        return (
            <>

            {
                !isConfirmed ? (
                    <>
                    <Header as='h5' onClick={handleBackPickDel} color={'blue'} className='m1'>
                        <Icon name='arrow circle left' />
                        <HeaderContent>Back</HeaderContent>
                    </Header>
                    <Header as="h2">Confirm your request!</Header>
                    <Segment raised className='m1'>
                        <Grid className='m05'>
                            {
                                LOAD_DETAILS.map((c,k)=>{
                                    return (
                                        <>
                                        <b className='pl2 ptop1'>Load # {k+1}</b>
                                        {renderServicesAvailed(c.SERVICES_AVAILED)}
                                        {renderDetergentAvailed(c.DETERGENTS_AVAILED)}
                                        {renderDetergentAvailed(c.FABCON_AVAILED)}
                                        </>
                                    )
                                })
                            }
                        </Grid>
                        <Grid className='m1'>
                        <GridRow columns={2} className='m05'>
                            <b className='pl2'>Pick-up Fee</b>
                            <GridColumn className='pef'><b>{`Php ${PICK_UP_FEE}.00`}</b></GridColumn>
                        </GridRow>
                        <GridRow columns={2} className='m05'>
                            <b className='pl2'>Delivery Fee</b>
                            <GridColumn className='pef'><b>{`Php ${DELIVERY_TOGGLE === false ? 0 : DELIVERY_FEE}.00`}</b></GridColumn>
                        </GridRow>
                        </Grid>
                    </Segment>
                    {renderFinalTotal()}
                    </>
                ) : (
                <>
                    <div className='img-confirm'>
                        <Image src={LoadingMachine} size='small' className='m1'/>
                    </div>
                    <div className='m1'>
                    <Header as="h1">Thank you for booking <br/>your laundry with us!</Header>
                    <br/>Your transaction code is
                    <Header as="h1">{tcode}</Header>
                    <br/><br/>
                    <Button size='large' color="blue" onClick={handleGoToTransactionsPage}>Go to transactions page</Button>
                    </div>
                </>
                )
            }

            </>
        )
    }

    const renderServicesAvailed = (s) => {
        return s.map((j,i)=>{
            return (
                <GridRow columns={2} className='p51'>
                    <GridColumn width={12} textAlign='left'>{j.LABEL}</GridColumn>
                    <GridColumn width={4}>Php {j.PRICE}.00</GridColumn>
                </GridRow>
            )
        })
    }

    const renderDetergentAvailed = (s) => {
        return s.map((j,i)=>{
            return (
                <GridRow columns={2} className='p51'>
                    <GridColumn width={12} textAlign='left'>{j.LABEL} x {j.MULTIPLIER}</GridColumn>
                    <GridColumn width={4}>Php {j.NEW_PRICE}.00</GridColumn>
                </GridRow>
            )
        })
    }

    const renderLaundryElement = () => {
        return (
            <>
                {
                LOAD_DETAILS.map((c,k)=>{
                        return (
                            <>
                            <Header as='h5' className='pl2'>Load # {k+1}</Header>
                            {renderServicesAvailed(c.SERVICES_AVAILED)}
                            {renderDetergentAvailed(c.DETERGENTS_AVAILED)}
                            {renderDetergentAvailed(c.FABCON_AVAILED)}
                            </>
                        )
                    })
                }
                {renderTotal('laundry')}
            </>
        )

    }

    const renderLaundryComputation = () => {
        return validate() && (
            <>
            <br/>
            <Divider horizontal>Laundry Fee Computation</Divider>
            <br/>
            <Grid>
                
                {renderLaundryElement()}

                <br/><br/><br/><br/><br/>
            </Grid>
            </>
        )
    }

    const computeTotal = () => {
        const sap = LOAD_DETAILS.map((l, k)=>{
            let sum = 0;
            let sa = LOAD_DETAILS.map((el) => el.SERVICES_AVAILED)[k].map((el) => { return el.PRICE })
            let da = LOAD_DETAILS.map((el) => el.DETERGENTS_AVAILED)[k]?.map((el) => { return el.NEW_PRICE })
            let fa = LOAD_DETAILS.map((el) => el.FABCON_AVAILED)[k]?.map((el) => { return el.NEW_PRICE })
    
            da[0] && sa.push(da[0])
            fa[0] && sa.push(fa[0])
            sa.forEach((el) => sum += el);
            
            return sum;
        })

        let loadSums = 0;
        sap.forEach((el) => loadSums += el);

        return loadSums
    }

    const computeTotalShip = () => {
        const { DELIVERY_TOGGLE, FEES } = USER_DETAILS
        const { PICK_UP_FEE, DELIVERY_FEE } = FEES

        return PICK_UP_FEE + (DELIVERY_TOGGLE === false ? 0 : DELIVERY_FEE)
    }

    const renderTotal = (identifier) => {
        return (
            <>
            <Header as="h2" fluid className='t-amount' style={{width: '100%'}}>
                <HeaderContent fluid>
                {
                    identifier === 'laundry' ? `Php ${computeTotal()}.00` : `Php ${computeTotalShip()}.00`
                }
                <HeaderSubheader>Total Amount</HeaderSubheader>
                </HeaderContent>
            </Header>
            </>
        )
    }

    const renderFinalTotal = () => {
        return (
            <>
            <Header as="h2" fluid className='t-amount' style={{width: '100%'}}>
                <HeaderContent fluid>
                {
                    `Php ${computeTotal() + computeTotalShip()}.00`
                }
                <HeaderSubheader>Total Amount</HeaderSubheader>
                </HeaderContent>
            </Header>
            </>
        )
    }

    const renderStepper = () => {
        return (
            <>
            <Grid className='stepper'>
                <GridRow columns={6} className='stepper-ui-icons'>
                    <GridColumn className='icon-grid'>
                        <Icon name={handleIcon(1)} color="yellow" size='big'></Icon>
                    </GridColumn>
                    <GridColumn>
                        <div><Header sub>Step 1</Header><span>Laundry options</span></div>
                    </GridColumn>
                    <GridColumn className='icon-grid'>
                        <Icon name={handleIcon(2)} color="yellow" size='big'></Icon>
                    </GridColumn>
                    <GridColumn>
                        <div><Header sub>Step 2</Header><span>Pick-up & Delivery</span></div>
                    </GridColumn>
                    <GridColumn className='icon-grid'>
                        <Icon name={handleIcon(3)} color="yellow" size='big'></Icon>
                    </GridColumn>
                    <GridColumn>
                        <div><Header sub>Step 3</Header><span>Confirm your <br/>order</span></div>
                    </GridColumn>
                </GridRow>
            </Grid>
            <Divider className='dvd'/>
            </>
        )
    }

    const renderLaundryOptions = () => {
        return (
            <div className='pd-sides'> 
            <Grid columns={1}>
                <GridColumn className='ptop0'>
                <FormField>
                    <Header as='h4' align='left' className='mtop0 mb0'>Indicate number of loads:</Header>
                    <p className='subHed'>Max of 7 kilos per load</p>
                    {renderLoadNumber()}
                </FormField>
                </GridColumn>
            </Grid>
            {renderAccordion()}
            {renderLaundryComputation()}
            </div>
        )
    }

    const renderLoadNumber = () => {
        return (
            <div className='load-number'>
                <Button icon='minus' size="large" onClick={()=>handleChangeLoad('-')} disabled={LOAD_DETAILS.length === 0} color='blue'/>
                <Header as='h5' className='label-load'>{LOAD_DETAILS.length}</Header>
                <Button icon='add' size="large" onClick={()=>handleChangeLoad('+')} disabled={LOAD_DETAILS.length === 5} color='blue'/>
            </div>
        )
    }

    const renderAccordion = () => {
        return (
            <Accordion className='t-left'>
                {
                    LOAD_DETAILS.map((i, k)=>{
                        return renderAccordionTitle(i, k)
                    })
                }
            </Accordion>
        )
    }

    const renderAccordionTitle = (i, k) => {
        return (
            <Segment raised className={activeAccordionIndex === k ? '' : 'p0'}>
                <AccordionTitle
                    className='accord-title'
                    active={activeAccordionIndex === k}
                    index={k}
                    onClick={() => handleAccordionClick(k)}
                >
                <Label attached='top' color='yellow'><Header className="title-accord-label" as='h4' color='' icon={'dropdown'} content={`Load # ${k+1}`} /></Label>
                </AccordionTitle>

                <AccordionContent active={activeAccordionIndex === k}>
                    
                    <Header as='h5' align='left' className='mtop0'>Choose the type of wash:</Header>
                    <ButtonGroup fluid compact={true} className='btn-grp-max'>
                        <Button color={handleColor(i, WASH_TYPE.DELICATE)} onClick={() => handleTypeOfWashButton(WASH_TYPE.DELICATE, k)}>Delicate<br/>28 mins</Button>
                        <Button color={handleColor(i, WASH_TYPE.NORMAL)} onClick={() => handleTypeOfWashButton(WASH_TYPE.NORMAL, k)}>Normal<br/>38mins</Button>
                        <Button color={handleColor(i, WASH_TYPE.HEAVY)} onClick={() => handleTypeOfWashButton(WASH_TYPE.HEAVY, k)}>Heavy<br/>48mins</Button>
                    </ButtonGroup>
                    
                            <Header as='h5' align='left' className='mtop1 mb0'>Choose your laundry detergent:</Header>
                            <p className='subHed'>{!LOAD_DETAILS[k].HAS_LAUNDRY_DETERGENT ? 'Customer-supplied detergent' : ''}</p>
                            <Grid>
                            <GridRow columns={3}>
                                <GridColumn width={3}>
                                <Checkbox name='ld' onChange={(e, d) => handleCheckboxToggle(d, k)} checked={LOAD_DETAILS[k].HAS_LAUNDRY_DETERGENT} toggle className='mtop05' label=""/>
                                </GridColumn>
                                <GridColumn width={8}>
                                    <Dropdown
                                        className='lft ldfc'
                                        placeholder='Liquid Detergent'
                                        selection
                                        options={LAUNDRY_DETERGENT}
                                        value={LOAD_DETAILS[k].LAUNDRY_DETERGENT}
                                        name="LAUNDRY_DETERGENT"
                                        onChange={(e, d) => handleSoapSelectionLD(e, d, k)}
                                        fluid
                                        disabled={!LOAD_DETAILS[k].HAS_LAUNDRY_DETERGENT}
                                    />
                                </GridColumn>
                                <GridColumn width={5}>
                                    {renderSoapNumber(k, 'ld', !LOAD_DETAILS[k].HAS_LAUNDRY_DETERGENT)}
                                </GridColumn>
                            </GridRow>
                            </Grid>

                            <Header as='h5' align='left' className='mtop1 mtop0 mb0'>Choose your fabric conditioner:</Header>
                            <p className='subHed'>{!LOAD_DETAILS[k].HAS_FABRIC_CONDITIONER ? 'Customer-supplied fabcon' : ''}</p>
                            <Grid>
                                <GridRow columns={3}>
                                    <GridColumn width={3}>
                                        <Checkbox name='fc' onChange={(e, d) => handleCheckboxToggle(d, k)} checked={LOAD_DETAILS[k].HAS_FABRIC_CONDITIONER} toggle className='mtop05' label=""/>
                                    </GridColumn>
                                    <GridColumn width={8}>
                                        <Dropdown 
                                            className='lft ldfc'
                                            placeholder='Fabric Conditioner'
                                            selection
                                            options={FABRIC_CONDITIONER}
                                            value={LOAD_DETAILS[k].FABRIC_CONDITIONER}
                                            name="FABRIC_CONDITIONER"
                                            onChange={(e, d) => handleSoapSelectionFC(e, d, k)}
                                            fluid
                                            disabled={!LOAD_DETAILS[k].HAS_FABRIC_CONDITIONER}
                                        />
                                    </GridColumn>
                                    <GridColumn width={5}>
                                        {renderSoapNumber(k, 'fc', !LOAD_DETAILS[k].HAS_FABRIC_CONDITIONER)}
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                            
                </AccordionContent>
            </Segment>
        )
    }

    const renderSoapNumber = (k, type, dis) => {
        if(type === 'ld'){
            return (
                <div className='soap-number'>
                    <Button size="tiny" disabled={dis} compact={true} icon='minus' onClick={()=>handleSoapLoadLD('-', k)} color='blue'/>
                    <Header as='h5' className='label-load' disabled={dis}>{dis ? 0 : LOAD_DETAILS[k].LAUNDRY_DETERGENT_QTY}</Header>
                    <Button size="tiny" disabled={dis} compact={true} icon='add' onClick={()=>handleSoapLoadLD('+', k)} color='blue'/>
                </div>
            )
        }else{
            return (
                <div className='soap-number'>
                    <Button size="tiny" disabled={dis} compact={true} icon='minus' onClick={()=>handleSoapLoadFC('-', k)} color='blue'/>
                    <Header as='h5' className='label-load' disabled={dis}>{dis ? 0 : LOAD_DETAILS[k].FABRIC_CONDITIONER_QTY}</Header>
                    <Button size="tiny" disabled={dis} compact={true} icon='add' onClick={()=>handleSoapLoadFC('+', k)} color='blue'/>
                </div>
            )
        }

    }

    const renderLoader = () => {
        return <Dimmer active={LOADER} className={'dim-loader'} >
            <Loader />
        </Dimmer>
    }

    const renderNotifModal = () => {
        const {OPEN, HEADER, CONTENT, HANDLER_TEXT} = NOTIF_MODAL
        return <Modal
            className='general-modal'
            size={'tiny'}
            open={OPEN}
            onClose={handleModal}
        >
            <ModalHeader>{HEADER}</ModalHeader>
            <ModalContent>
            <p>{CONTENT}</p>
            </ModalContent>
            <ModalActions>
            <Button color="blue" onClick={HANDLER_TEXT === 'Reload' ? ()=>window.location.reload() : () => {}}>
                {HANDLER_TEXT}
            </Button>
            </ModalActions>
        </Modal>
    }

    const renderOnline = () => {
        return (
            <>
            <div className="dashboard">
                <div className='dashboard-content' style={{height: window.innerHeight-122.29}}>
                    {!isConfirmed && renderStepper()}
                    {step === 1 && renderLaundryOptions()}
                    {step === 2 && renderPickDeliveryOptions()}
                    {step === 3 && renderConfirm()}
                    {renderLoader()}
                    {renderNotifModal()}
                </div>
            </div>
            <div className={`fixed-footer ${step === 3 && 'fin'} `}>
                {renderFooter()}
            </div>
            </>
        )
    }

    const handlePrint = () => {
        setPrintModal(true)
    }

    const renderPrintModal = () => {
        return (
            <Modal
            className='print-modal'
            size={'tiny'}
            open={printModal}
            onClose={()=>{}}
            >
                <ModalHeader>{'Print Preview'}</ModalHeader>
                <ModalContent>
                    <WPrint ref={componentRefWB} renderTotal={renderTotal('laundry')}/>
                </ModalContent>
            </Modal>
        )
    }

    const renderWalkIn = () => {
        return (
            <>
            <div className="dashboard">
                <div className='dashboard-content' style={{height: window.innerHeight-122.29}}>
                    {step === 1 && renderLaundryOptions()}
                    {renderLoader()}
                    {renderPrintModal()}
                </div>
            </div>
            <div className={`fixed-footer ${step === 3 && 'fin'} `}>
                <Button fluid size='big' compact={true} color={'blue'}
                    onClick={handlePrint}
                    disabled={step === 1 ? !validate() : !validate2() }
                >GENERATE WAY BILL</Button> 
            </div>
            
            </>
        )
    }

    return (
        <>
        {isWalkIn ? renderWalkIn() : renderOnline()}
        </>
    )
}

export default Dashboard