import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import { PAGES } from './utils/constants'
import Dashboard from './components/Dashboard';
import Transactions from './components/Transactions';
import WalkIns from './components/WalkIns';
import { ModalContent, Image, Modal, Button, Header } from 'semantic-ui-react';
import Logo from './assets/login-logo.png'
import ServicePrice from './assets/service-price-list.jpg'
import { useState } from 'react';
import ReactGA from 'react-ga4';


function App() {
  ReactGA.initialize('G-Q0GNWG81S5');
  const [modalPrice, setModalPrice] = useState(true)

  const renderHeader = () => {
    return (
        <div className='fixed-header'>
        <div className='d-header'>
            <Image src={Logo} size='small' className='image-logo' />
            {/* <Icon name="phone" size='large' className='call'></Icon> */}
        </div>
        </div>
    )
  }

  const handleCloseModal = () => {
     setModalPrice(false)
  }

  const renderModal = () => {
    return (
      <Modal
      size={'tiny'}
      open={modalPrice}
      onClose={handleCloseModal}
      dimmer={'default'}
      closeOnDimmerClick={false}
      >
        <ModalContent>
          <Image src={ServicePrice} size='large'/>
          <br/>
          <Button color={'blue'} onClick={handleCloseModal} fluid>
            BOOK PICK UP NOW
          </Button>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <div className="App">
    
        <Router>
          <Routes>
          </Routes>
            {renderHeader()}
            <Routes>
                <Route path={PAGES.REQUEST} element={<Dashboard/>} />
                <Route path={PAGES.WALKINS} element={<WalkIns/>} />
                {/* <Route path={PAGES.PROFILE} element={<Profile/>} />*/}
                <Route path={PAGES.TRANSACTIONS} element={<Transactions/>} /> 
            </Routes>
        </Router>
        { window.location.pathname === '/' && renderModal() }
    </div>
  );
}

export default App;